import React, {useState} from 'react';
import Modal from 'react-modal'
import {storage} from "../../firebase";
import {ref, uploadBytesResumable, getDownloadURL} from "firebase/storage"
import { v4 } from 'uuid';
import { db } from '../../firebase';
import { doc, setDoc, Timestamp } from 'firebase/firestore/lite';
import { DayPicker, useInput } from "react-day-picker";

import "react-day-picker/dist/style.css";


const TimelineEvent = (props) => {
  const {showModal, setShowModal, edit, timelineID, getTimelines} = props;
  const { inputProps, dayPickerProps } = useInput({
    defaultSelected: new Date(),
    fromYear: 2021,
    toYear: 2099,
    format: 'MM/dd/yyyy',
    required: true
  });
  const defaultValues = {
    event_name: '',
    location: '',
    description: '',
    file: {},
  }





  const [errorHandler, setErrorHandler] = useState({});
  const [formValues, setFormValues] = useState(defaultValues)
  const handleClose = () => {setShowModal(false)}
  const modalType = edit ? "Edit" : "Create"
  let width = "50%"
  let height = "80%"
  const windowWidth = window.screen.width;
  if (windowWidth < 431
    ) {
    height= "80%"
    width = "95%"
  }
  const style =  {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width,
    padding: "0.5rem",
    overflowY: "scroll",
    height,
  },
}
const handleFocus = (event) => {
  // On Clicking into Input field (this will define it as being TOUCHED)
  // so if it has not been touched the input name will not even show as a key for Error Handler defaulting to false
  // This will also reset the state of it being touched if its clicked on again to remove the error
  setErrorHandler({...errorHandler, [event.target.id]: false})
}
const handleBlur = (event) => {
  //Here we grab the input and the value
  const value = event.target.value;
  const input = event.target.id

  //If Value is empty we set the Error Handler to error at input field
  // it will read {'event-name': true} this notes as the input 'event-name' has an error currently
  // can add various checks here
  if (!value) {
  setErrorHandler({...errorHandler, [input]: true})
  }
}

  const uploadMedia = (e) => {
    setFormValues({...formValues, file: e.target.files[0]});
}

const createEvent = async (e) => {
  // API call to firebase should fire here
  e.preventDefault();
  const timelineref = doc(db, 'events', v4());
  const media = formValues.file
  if(media.name) {
    const imageRef = ref(storage, `images/${media.name + v4()}`)
    const uploadTask = uploadBytesResumable(imageRef, media);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.log("Error uploading file: ", error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await setDoc(timelineref, 
            {...formValues, file: downloadURL, timeline_id: timelineID, date: Timestamp.fromDate(new Date(inputProps.value))  }
            );
            getTimelines();
            handleClose();
        } catch (error) {
          console.error("Error getting download URL: ", error);
        }
      }
    )
  }
  else {
    await setDoc(timelineref, {...formValues, timeline_id: timelineID, date: Timestamp.fromDate(new Date(inputProps.value))});
  }


}
 const handleInputChange = (e) => {
  const inputType = e.target.id;
  const value = e.target.value;
  setFormValues({...formValues, [inputType]: value});

 }

 const isDisabled = () => {
  const errorFields = Object.keys(errorHandler);
  let errorExists = false;
  
  if (errorFields.length === 0) {return true}
  
  Object.keys(formValues).forEach((errorField) => {
    if (errorHandler[errorField] || !formValues[errorField]) {
      errorExists = true; 
    }


  })
  return errorExists;
 }

   const footer = (
      <label>
        <input id="date" {...inputProps} />
      </label>
  );
  return (
    <Modal
      isOpen={showModal}
      style={style}
      contentLabel={`${modalType} Event`}
    >
      <div className="modal-close" onClick={handleClose}><i class="fa fa-window-close fa-xl" aria-hidden="true"></i></div>
      <form className="event-form" onSubmit={createEvent}>
           <div className="event-form-header">
      <h3>Add an event</h3>
      </div>
        <label htmlFor='event_name'><h5>Event Name</h5></label>
        <div className="field">
        <input
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
          value={formValues['event_name']}
          id="event_name"
          type="text"
          placeholder='Korean Spa'
           />
        <div className={`${errorHandler["event_name"] ? "error" : ''}  line`} />
        </div>
        <label htmlFor='date'><h5>Date</h5></label>
        <div className={`${errorHandler["date"] ? "error" : ''} date-field`}>
        <DayPicker
          footer={footer}
          {...dayPickerProps}
          format="MM/DD/YYYY"
          // onSelect={setSelectedDate}
          // selected={selectedDate}
          // mode="single"
          id="date"
          />
        {/* <input
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
          value={formValues['date']}
          id="date"
          type="date"
          placeholder={new Date()}
           /> */}
           
        </div>
        <label htmlFor='location'><h5>Location</h5></label>
        <div className="field">
        <input
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleInputChange}
        value={formValues['location']}
        id="location"
        type="text"
        placeholder='Irvine' />
        <div className={`${errorHandler["location"] ? "error" : ''}  line`} />
        </div>
        <div>
        <label htmlFor='description'><h5>Description</h5></label>
        <div className='description-container'>
        <textarea
        required={true}
        className={`${errorHandler["description"] ? "error" : ''} description`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleInputChange}
        value={formValues['description']}
        id="description"
        placeholder='Short Trip to Irvine to enjoy the etc...'></textarea>
        </div>
        </div>
        <div>
        <label htmlFor='file'>
        <input
        htmlFor="file"
        type="file"
        onChange={uploadMedia}
        id="file"
        />
          <div className='upload-button'>Upload Image</div>
          <span className="file-name">{
         formValues.file.name   ? formValues.file.name : ''
        }</span>
          </label>
        </div>
        {
         formValues.file.name   ? <img className="img-upload" src={URL.createObjectURL(formValues.file)} />: ''
        }
        <div className="modal-button-container">
        <button className={`${isDisabled() ? 'disabled' : ''} button-19`}  disabled={isDisabled()}type="submit" submit={false} onSubmit={createEvent}>Submit</button>
        <button className="button-generic" type="button" onClick={handleClose}>Cancel</button>
        </div>
      </form>
    </Modal>
  );
};

export default TimelineEvent;