import React, {useState, useEffect} from 'react';
import TimelineEvent from '../TimeLineEvent/TimeLineEvent'
import TimeLineEventModal from '../TimeLineEventModal/TimeLineEventModal';
import { db } from '../../firebase';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore/lite';
const Timeline = (props) => {
  const {editMode} = props;
  const [eventModalShow, setEventModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [timelines, setTimelines] = useState([]);
  const [events, setEvents] = useState([])
  const [sortBy, setSortBy] = useState("false")


  const getTimelines = async() => {
    setIsLoading(true);
    const timelineCol = collection(db, "timelines");

    const eventsQuery =  query(collection(db, 'events'), where("timeline_id", "==", "561LWAGKcNfsggInf9Nq"), orderBy("date", sortBy === "true" ? 'desc' : 'asc'))
    const data = await getDocs(timelineCol);
    const eventsData = await getDocs(eventsQuery);
    setTimelines(data.docs.map((doc)=> ({...doc.data(), id: doc.id})));
    setEvents(eventsData.docs.map((eventsDoc)=> ({...eventsDoc.data(), id: eventsDoc.id})));
    setIsLoading(false);
  }

  const handleSort = (e) => {
    setSortBy(e.target.value)
  }

  useEffect(()=> {
    getTimelines();
  }, [sortBy])
  return (
    <div className="timeline-container">
      { isLoading ? <div className='loader' ></div> : 
     (<>
    <div className='sticky-add-event button'><button className='button-19' onClick={()=> {setEventModalShow(true)}}>Add Event <i class="fa-solid fa-circle-plus fa-xl add-event" style={{color: "green"}} ></i>
        </button></div>
      { timelines[0] ?
      <TimeLineEventModal showModal={eventModalShow} setShowModal={setEventModalShow} getTimelines={getTimelines} timelineID={timelines[0].id}/> : null}
       { timelines[0] ? <h1 className="timeline-title"> {timelines[0].timeline_name} </h1> : null}
       <div className="sort-timeline"><span>Sort By: </span>
       <select type="select" onChange={handleSort} value={String(sortBy)}><option value={"true"}>Newest</option><option value={"false"}>Oldest</option></select></div>
      <ul className="timeline" >
          {events.length !== 0 ? events.map((event, index) => (
        <TimelineEvent key={index} event={event} index={index} editMode={editMode} getTimelines={getTimelines} />
      )) : <div> No Events Found </div>}  
      </ul>
    </>)}
    </div>
  );
};

export default Timeline;