import React, { useState } from 'react';
import Modal from 'react-modal';
import { doc, deleteDoc } from 'firebase/firestore/lite';
import { db } from '../../firebase';

import { Timestamp } from 'firebase/firestore/lite';


const TimelineEvent = ({ event, index, editMode, getTimelines}) => {
  const { date, event_name, description, file, id } = event;
  const [editModalShown, setEditModalShown] = useState(false);
  const handleClose = () => setEditModalShown(false);
  const deleteEvent = async () => {
    try {
   await deleteDoc(doc(db, "events", id));
   getTimelines();
   handleClose();
    }
    catch (error) {
      console.log(error)
    } 
  }

  let width = "50%"
  let height = "auto"
  const windowWidth = window.screen.width;
  if (windowWidth < 431
    ) {
      height= "80%"
    width = "90%"
  }
  const handleDeleteClick = () =>{
      setEditModalShown(true)
  }

  const style =  {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width,
      padding: "0.5rem",
      overflowY: "auto",
      height,
    },
  }

  const color = index % 2 !== 0 ? '#F8C8DC' : '#006400';
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const timeValue = date ? new Timestamp(date.seconds, 0) : null;
  const formattedDate = new Date(date ? timeValue.toDate() : 0)
  return (
    <>
    <Modal
      isOpen={editModalShown}
      style={style}
      contentLabel={`Delete Event`}
      handleClose
    >
        <div className="modal-close" onClick={handleClose}><i class="fa fa-window-close fa-xl" aria-hidden="true"></i></div>
      <div>
        <div className="delete-button-container">
          <div> <h4>Please confirm you would like to delete the event "{event_name}"</h4></div>
          <button className='button-19' onClick={deleteEvent}>Confirm</button>
          <button className='handleClose button-generic' onClick={handleClose}>Cancel</button>
          </div>
      </div>
    </Modal>
    <li style={{'--accent-color': `${color}`}}>
        <div class="date">{formattedDate.toLocaleDateString("en-US", options)}</div>
        <div class="title">{event_name}</div>
        <div class="descr">{description}</div>
        {typeof file === "string"  ? (
        <div className="event-image">
          <img src={file} alt={event_name} />
        </div>) : null
      }
      {editMode ? <div><i className='fa fa-trash fa-lg shake' onClick={handleDeleteClick} style={{color: "red"}}></i></div> : null}
    </li>
   </>

  );
};

export default TimelineEvent;