import React, { useState } from 'react';
import Modal from 'react-modal';
import Timeline from './components/TimeLineDisplay/TimeLineDisplay';
import NavBar from './components/NavBar/NavBar';
import './App.css';
import 'bootstrap'

const App = () => {
  const [editMode, setEditMode] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [password, setPassword] = useState(null);
  const handleInputChange = (e) => {
    setPasswordInput(e.target.value);
  }
  let width = "50%"
  let height = "auto"
  const windowWidth = window.screen.width;
  if (windowWidth < 431
    ) {
      height= "80%"
    width = "90%"
  }
  const style =  {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width,
    padding: "0.5rem",
    overflowY: "auto",
    height,
  },
}
  const handleSubmit = (e) => {
    e.preventDefault();
    setPassword(passwordInput);
  }
  return (

    
    <div className="app">
      { password === 'charinaandtien' ? (
        <>
      <NavBar editMode={editMode} setEditMode={setEditMode}/>
      <Timeline editMode={editMode}/>
      </>
      )
      :
      <Modal
        isOpen={true}
        style={style}
      >
           <label htmlFor='password'><h5>Enter Password for Access</h5></label>
        <form className="event-form" onSubmit={handleSubmit}>
        <div className="field">
        <input
          onChange={handleInputChange}
          value={passwordInput}
          id="password"
          type="password"
          placeholder='Enter Password'
          autoComplete='off'
           />
        <div className={`${password && password !== 'charinaandtien' ? "error" : ''}  line`} />
        </div>
        <button className='button-19' type="submit">Submit</button>
        </form>
      </Modal>}
    </div>

  ) 

};

export default App;